<template>
  <div class="fluid" id="assetCategoryList" v-if="permission.read_perm == 1">
    <div>
      <div class="asset-category-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                ASSET MANAGEMENT SYSTEM
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="asset-category-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title>Kategori Aset</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <div v-if="isAdmin">
                    <div
                      v-if="permission.update_perm == 1"
                      style="width: 120px"
                    >
                      <v-select
                        :disabled="selectedData.length === 0"
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Delete' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <!-- <div v-if="isAdmin" style="width:200px;margin-right:10px;"> -->
                  <div class="asset-category-list-toolbar-div">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="cari aset"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      style="position: relative; top: 15px; width: 10px;
                        margin-left: 10px;"
                      @keyup.enter="searchEnter"
                    ></v-text-field>
                    <div
                      style="margin-left:20px;display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                        @click="searchEnter"
                      >
                        search
                      </v-btn>
                    </div>
                  </div>
                </v-toolbar>
                <v-toolbar
                  class="asset-category-list-toolbar-1"
                  flat
                  style="display:flex;justify-content:flex-start;align-items:center;"
                >
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size:12px;margin-right:20px;"
                        @click="addCategory"
                      >
                        Tambah kategori
                      </v-btn>
                    </template>

                    <v-form
                      ref="entryForm"
                      @submit.prevent="submit"
                      style="position: relative;"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Tambah Kategori
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-col
                            md="12"
                            style="padding: 0; position:relative; top:20px;"
                          >
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Nama Kategori
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0;">
                                <v-text-field
                                  v-model="form.name"
                                  outlined
                                  :rules="nameRules"
                                />
                              </v-col>
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Kode
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0;">
                                <v-text-field v-model="form.code" outlined />
                              </v-col>
                            </div>
                          </v-col>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn color="error" text outlined @click="close">
                            Batal
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" type="submit" text outlined>
                            Simpan
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </div>
              <v-toolbar
                class="asset-category-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                fixed-header
                height="55vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :show-select="permission.update_perm == 1 && isAdmin"
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.category.name }}
                  </div>
                </template>
                <template v-slot:[`item.is_sync_from_catalogue`]="{ item }">
                  <div
                    :style="
                      `font-size:12px; padding: 0; display: flex; flex-direction: row; color:${
                        item.is_sync_from_catalogue === 1 ? 'blue' : 'red'
                      };`
                    "
                  >
                    {{ item.is_sync_from_catalogue === 1 ? 'True' : 'False' }}
                  </div>
                </template>
                <template v-slot:[`item.sync_at`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.sync_at) }}
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.created_at) }}
                  </div>
                </template>
              </v-data-table>

              <div style="height:0px;overflow:hidden;"></div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <div style="position:fixed; bottom:10px; right:0;">
      <Scanner />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    Scanner: () => import('../../home/components/Scanner.vue')
  },
  data: () => ({
    dialog: false,
    asset: buildType.apiURL('asset'),
    build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: false,
    menu_key: 42,
    permission: {},
    application: {
      id: 18,
      name: 'e-Asset Management System'
    },
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      company_id: 0,
      dept_id: '',
      itemsPerPage: 10
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Nama Kategori',
        value: 'name',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Kode',
        value: 'code',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Sync',
        value: 'is_sync_from_catalogue',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Tgl. Sync',
        value: 'sync_at',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Sync Oleh',
        value: 'sync_by_name',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Tgl. Buat',
        value: 'created_at',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Dibuat Oleh',
        value: 'created_by_name',
        align: 'left',
        sortable: false
        // class: 'black--text'
      }
    ],
    company: [],
    department: [],
    result: [],
    selectedData: [],
    dropDownCompany: [],
    loading: true,
    itemEdit: null,
    detail: null,
    form: {
      act: 'add',
      id: '',
      name: '',
      code: ''
    },
    nameRules: [],
    codeRules: []
  }),

  mounted() {
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            if (arr[i].id === '1' || arr[i].id === '29') {
              this.isAdmin = true
            } else {
              this.isAdmin = false
            }
            this.paramAPI.company_id = this.getUserProfile.employee.company_id
            this.permission = result.data
            this.setPermission(result.data)
            this.getDataFromApi()
            this.setAction()
          })
          .catch(error => {
            if (error) {
              this.isAdmin = false
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = false
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapMutations(['setPermission', 'setParamAsset']),
    ...mapActions(['menu_access', 'dropdownPlant']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    close() {
      this.form = {
        act: 'add',
        id: '',
        name: '',
        code: ''
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.permission.delete_perm === 1) {
            this.delete()
          }
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    setAction() {
      this.setParamAsset(null)
      // this.itemEdit = null
      this.actionValue = 0
      this.selectedData = []
    },

    getDataFromApi() {
      this.loading = true
      this.initDataTable().then(data => {
        this.result = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    initDataTable() {
      return new Promise(resolve => {
        axios
          .get(
            `${this.asset}asset_category/list?keyword=${
              this.paramAPI.keyword
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }`
          )
          .then(res => {
            // console.log(res.data)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    addCategory() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    rowClick(pItem) {
      this.form.act = 'update'
      this.form.id = pItem.id
      this.form.name = pItem.name
      this.form.code = pItem.code
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(res => {
        if (res.isConfirmed) {
          axios
            .delete(
              `${this.asset}asset_category/delete/${this.selectedData[0].id}`
            )
            .then(res => {
              this.actionValue = 0
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      this.getDataFromApi()
    },
    updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      this.getDataFromApi()
    },

    submit() {
      this.nameRules = [v => !!v || 'Name is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        code: this.form.code,
        name: this.form.name
      }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log(form)
      this.save(form)
    },
    save(form) {
      axios
        .post(`${this.asset}asset_category/save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    }
  }
}
</script>
<style lang="scss">
#assetCategoryList {
  position: relative;
  .asset-category-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .asset-category-list-toolbar-1 {
      .asset-category-list-toolbar-div {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .asset-category-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #assetCategoryList {
    .asset-category-list-div {
      .asset-category-list-toolbar-1 {
        .asset-category-list-toolbar-div {
          display: none;
        }
      }
      .asset-category-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
